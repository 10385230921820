import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";

import Dashboard from "../component/dashboard/Dashboard";
import Report from "../component/request/Report";
import Upload from "../component/upload/Upload";
import Loginpage from "../component/login/Loginpage";

const Index = ({roleData, setRoleData}) => {
    const role = JSON.parse(localStorage.getItem('role')) || []
    console.log('rollll',roleData)
    // role.includes("dashboard")

    useEffect(()=>{
        if(roleData !== undefined){
        console.log('rollll',roleData)
        }else{
            console.log("Hello")
        }
    },[roleData])

  return (
    <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Loginpage setRoleData={setRoleData} />} />
        {roleData && roleData.includes("dashboard") &&
        <Route path="/dashboard" element={<Dashboard />} />
        }
        
        {/* <Route path="/sidebar" element={<Sidebar />} /> */}
        {/* {role.includes("report") && */}
        <Route path="/report" element={<Report />} />
        {/* } */}
        {role.includes("upload") &&
        <Route path="/upload" element={<Upload />} />
        }
        {/* <Route path="/navbar" element={<Navbar />} /> */}
        {/* <Route path="/demo" element={<Demo />} /> */}
        {/* <Route path="/drag" element={<Drag />} /> */}
    </Routes>
  )
}

export default Index


