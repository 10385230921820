import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    // const login = () => setIsLoggedIn(true);
    // const logout = () => setIsLoggedIn(false);
  
    // const value = { isLoggedIn, login, logout };
  
    return <AuthContext.Provider value={{isLoggedIn, setIsLoggedIn}}>{children}</AuthContext.Provider>;
};

export {AuthContext, AuthProvider}