import React, { useEffect, useState } from 'react'
import ArrowLeft from "../../assets/keyboard_arrow_left.svg"
import "./Activity.css"
import axios from 'axios'
import config from '../utils/config'
import { Await } from 'react-router-dom'

const DARRemark = ({closeForm,id,darOldstatus,getDarNewStatus,updateDarVisit}) => {
    const [statusData,setStatusData] = useState({
        visitid: id,
        oldstatus:darOldstatus || "Open",
        newstatus: darOldstatus || "Open",
        remark: '',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatusData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setSubmitLoader(false)
    };
    const [statusDropdown,setStatusDropdown] = useState([]);
    const [submitLoader,setSubmitLoader] = useState(false)
    const [errObj,setErrObj] = useState({})

    const role = JSON.parse(localStorage.getItem('role')) || []

    console.log('get status',darOldstatus)
    const validate = () => {
        const error ={};
        if(!statusData?.remark) {
            error.remark = true
        }
        setErrObj(error)
        return error
        // return Object.keys(errObj).length === 0
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        const payload = {...statusData, email: localStorage.getItem("superonAdmin")};
        const errors = validate()
        setErrObj(errors)
        if(Object.keys(errors).length !== 0) {
            console.log('show error')
            setSubmitLoader(false)
            return
        }
        console.log('form',payload)
        // return
        try {
            console.log('status form',payload);
            setSubmitLoader(false)
            // return
            const res = await axios.post(config.ipAddress + `/adddaractivity`, payload)
            console.log(res)
            setSubmitLoader(false)
            getDarNewStatus(payload?.newstatus)
            updateDarVisit(id,payload?.newstatus)
            closeForm()
        } catch (error) {
            setSubmitLoader(false)
        }
    
        setSubmitLoader(true)
        closeForm();
      };
    const fetchStatus = async () => {
        const res = await axios.get(config.ipAddress + `/getdarstatus`)
        console.log('status res',res?.data?.data?.statuses)
        const getRes = res?.data?.data?.statuses
        setStatusDropdown(getRes)
    }
    useEffect(()=> {
        fetchStatus()
    },[]);
    return (
        <div className='drawer'>
            <div className="drawer-header">
                <div
                    className="flex flex-wrap justify-between items-center cursor-pointer drawer-heading"
                >
                    <img
                        src={ArrowLeft}
                        className="mr-3"
                        onClick={() => closeForm()}
                    />
                    <span
                        onClick={() => closeForm()}
                        className='mr-auto'
                    >Status Remark</span>
                </div>
            </div>
            <div className="drawer-body">
                <div className="drawer-form">
                    {darOldstatus == "Close" ? null 
                        :
                        <div className="flex flex-wrap justify-between mb-2">
                            <label>Status</label>
                            <div className="property">
                                <select
                                    className="dropdown"
                                    name="newstatus"
                                    value={statusData.newstatus}
                                    onChange={handleChange}
                                >
                                    {statusDropdown?.length &&
                                        statusDropdown.map((item)=>{
                                            return (
                                                item?.name == "Open" ? <option value={item?.name} selected={item?.name == "Open"}>{item?.name}</option> : <option value={item?.name}>{item?.name}</option>
                                                
                                                // <option
                                                //     value={item?.name}
                                                //     selected={item?.name === "Open" ? true : false}
                                                // >
                                                //     {item?.name}
                                                // </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    <div className="flex flex-wrap justify-between">
                        <label>Remark</label>
                        <div className="property">
                            <textarea
                                name="remark"
                                value={statusData.remark}
                                onChange={handleChange}
                                rows={5}
                                placeholder='Type here'
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="drawer-footer">
                <button
                onClick={() => closeForm()}
                className="btn mr-2"
                >
                Cancel
                </button>
                <button
                // className={`btn-drawer ${submitLoader ? "!cursor-default":"!cursor-pointer"}`}
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={submitLoader ? true: false}
                >
                {/* {submitLoader ? <CircleLoading />:'Submit'} */}
                Submit
                </button>
            </div>
        </div>
    )
}

export default DARRemark
