import React, { useEffect, useState } from 'react'
import ArrowLeft from "../../assets/keyboard_arrow_left.svg"
import "./Activity.css"
import axios from 'axios'
import config from '../utils/config'
import { Await } from 'react-router-dom'
import SampleRequestActivity from './SampleRequestActivity'
import { Drawer } from '@mui/material'

const StatusRemark = ({closeForm,id,oldstatus,getNewStatus,sampleObj}) => {
     const [statusData,setStatusData] = useState({
        itemid: [id],
        oldstatus:oldstatus || "New",
        newstatus: oldstatus || "New",
        remark: '',
        lr_no: '',
        transporter_detail: ''
    })
    const [sampleActivity,setSampleActivity] = useState(false);
    const [obj,setObj] = useState(sampleObj);
    const [statusResponse,setStatusResponse]=useState("");
    const [activityId,setActivityId] = useState(null);
    const [allActivityData,setAllActivityData] = useState([]);
    useEffect(() =>{
        setObj(sampleObj)
    },[sampleObj])

    useEffect(() =>{
        console.log("hiiiiiiiiii",{
            sampleObj
        })
    },[sampleObj])

    const handleChecked = (sample) => {
        // const { checked, id } = e.target;
    
        // Update the object array
        const updatedObj = obj.map(item => {
            if (Number(item.itemid) === Number(sample.itemid)) {
                return { ...item, isChecked: !sample.isChecked };
            }
            return item;
        });
    
        // Update checked items for display
        // const updatedCheckedItems = updatedObj
        //     .filter(item => item.isChecked)
        //     .map(item => item.itemid);
    
        setObj(updatedObj); // Update the state for obj
        // setCheckedItems(updatedCheckedItems); // Update the state for checked items
    
        console.log('Updated object:', updatedObj);
        // console.log('Checked items:', updatedCheckedItems);
    };

    const result = (obj) => obj
  ?.filter((item) => item.isChecked === true)
  ?.map((item) => item.itemid)
  ?.join(', ');

  
  const result1 = (obj) => obj
  ?.filter((item) => item.isChecked === true)
  ?.map((item) => item.itemid)
    

    // const handleChecked = (e) => {
    //     const {checked, id} = e.target
    //     console.log('checkbox', checked, id)
    //     // setCheckedItems((prevState) =>
    //     //     checked
    //     //       ? [...prevState, id] // Add the value to the array if checked
    //     //       : prevState.filter((item) => item !== id) // Remove the value if unchecked
    //     // );
    //     const updatedObj  = sampleObj.map(item => {
    //         if(item.itemid === id) {
    //            return {
    //             ...item,
    //             isChecked: checked
    //            }
    //         } else {
    //             return {
    //                 ...item
    //             }
    //         }
    //     })
    //     setObj(updatedObj)
    //      console.log('checkbox id',{updatedObj, checked})
    // }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatusData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrObj({
            [name]: false
        })
    };
    const [statusDropdown,setStatusDropdown] = useState([]);
    const [submitLoader,setSubmitLoader] = useState(false)
    const [errObj,setErrObj] = useState({})

    console.log('sample obj',sampleObj)

    const role = JSON.parse(localStorage.getItem('role')) || []

    console.log('get status',oldstatus)
    const allActivity = async ()=> {
        const tempId = sampleObj?.map((item)=> {
            return item.itemid
        })
        const res = await axios.post(config.ipAddress + `/getsampleactivitynew`, {itemid:tempId})
        const getRes = res?.data?.data
        setAllActivityData(getRes)
        // setActivityId(tempId)
        setSampleActivity(true)
    }
    const validate = () => {
        const error ={};
        if(!statusData?.remark) {
            error.remark = true
        }
        setErrObj(error)
        return error
        // return Object.keys(errObj).length === 0
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        const payload = {...statusData, itemid: result1(obj), email: localStorage.getItem("superonAdmin")};
        const errors = validate()
        setErrObj(errors)
        // if(Object.keys(errors).length !== 0) {
        //     console.log('show error')
        //     setSubmitLoader(false)
        //     return
        // }
        console.log('form',payload)
        // return
        try {
            console.log('status form',payload);
            // return
            const res = await axios.post(config.ipAddress + `/addsampleactivity`, payload)
            console.log('sdfd',res?.data?.message)
            if(statusData?.newstatus == "Completed")  {
                closeForm(true)
            }
            setStatusResponse(res?.data?.message);
            setStatusData({
                ...statusData,
                remark: ''
            })
            setSubmitLoader(false)
            getNewStatus(payload?.newstatus)
            // closeForm()
        } catch (error) {
            setSubmitLoader(false)
        }
    
        // setSubmitLoader(true)
        // closeForm();
      };
      useEffect(()=> {
        const fetchStatus = async () => {
            const res = await axios.get(config.ipAddress + `/getsamplerequeststatus`)
            console.log('status',res?.data?.data?.statuses)
            const getRes = res?.data?.data?.statuses
            setStatusDropdown(getRes)
        }
        fetchStatus()
      },[]);
      useEffect( () => {
        setTimeout( ()=> {
            setStatusResponse("");
        },8000)
      },[statusResponse])
    return (
        <>
        <div className='drawer'>
            <div className="drawer-header">
                <div
                    className="flex flex-wrap justify-between items-center cursor-pointer drawer-heading"
                >
                    <img
                        src={ArrowLeft}
                        className="mr-3"
                        onClick={() => closeForm()}
                    />
                    <span
                        onClick={() => closeForm()}
                        className='mr-auto'
                    >Status Remark</span>
                    <div
                        className='ml-auto text-[14px] font-normal text-[#ec691f]'
                        onClick={allActivity}
                    >View Activity</div>
                </div>
            </div>
            <div className="drawer-body">
                <div className="drawer-form">
                    {statusData.oldstatus == "Completed" ? null 
                    :
                    role.includes("sample-request-status-permission")?
                    <>
                    <div className="sample-item-name mb-6 border border-[#ccc] px-3 pt-3 rounded-lg">
                        <ul className='flex flex-wrap'>
                            {obj?.map((sample) => {
                                return (
                                    <>
                                        {sample?.status === "Completed" ? 
                                        null
                                        :
                                            <li className='mr-4 text-[14px] mb-2'>
                                                <label className='flex flex-wrap items-center'
                                                htmlFor={sample?.itemid}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className='mr-2'
                                                        id={sample?.itemid}
                                                        checked={sample?.isChecked === true ? 
                                                            true : false
                                                        }
                                                        // checked={checkedItems.includes(sample?.itemid)}
                                                        // value={sample?.itemid}
                                                        onChange={() => handleChecked(sample)}
                                                    />
                                                    {sample?.item}
                                                </label>
                                            </li>
                                        }
                                    </>
                                )
                            })}
                        </ul>
                        {/* <p>Selected Items: {result(obj)}</p> */}
                    </div>
                    <div className="flex flex-wrap justify-between mb-2">
                        <label>Status</label>
                        <div className="property">
                            <select
                                className="dropdown"
                                name="newstatus"
                                value={statusData.newstatus}
                                onChange={handleChange}
                            >
                                {statusDropdown?.length &&
                                    statusDropdown.map((item)=>{
                                        return (
                                            item?.name == "New" ? <option value={item?.name} selected>{item?.name}</option> : <option value={item?.name}>{item?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    </>
                    :''
                    
                    }
                    {(statusData.newstatus == "Completed" && statusData?.oldstatus !== "Completed") ?
                        <>
                            <div className="flex flex-wrap justify-between mb-2">
                                <label>LR No.</label>
                                <div className="property">
                                    <input
                                        type='text'
                                        name="lr_no"
                                        // value={statusData.remark}
                                        onChange={handleChange}
                                        // rows={5}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between mb-2">
                                <label>Transporter Detail</label>
                                <div className="property">
                                    <textarea
                                        name="transporter_detail"
                                        // value={statusData.remark}
                                        onChange={handleChange}
                                        rows={5}
                                        placeholder='Type here'
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    <div className="flex flex-wrap justify-between">
                        <label>Remark</label>
                        <div className="property">
                            <textarea
                                name="remark"
                                value={statusData.remark}
                                onChange={handleChange}
                                rows={5}
                                placeholder='Type here'
                                // className={`${errObj?.remark ? 'error-field' : ''}`}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="drawer-footer relative">
                {statusResponse && <p className='absolute -top-4 right-0 pr-6 text-[14px] text-right text-green-600'>{statusResponse}</p>}
                <button
                onClick={() => closeForm()}
                className="btn mr-2"
                >
                Close
                </button>
                <button
                // className={`btn-drawer ${submitLoader ? "!cursor-default":"!cursor-pointer"}`}
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={submitLoader ? true: false}
                >
                {/* {submitLoader ? <CircleLoading />:'Submit'} */}
                Submit
                </button>
            </div>
        </div>
        <Drawer
            anchor="right"
            hideBackdrop={false}
            open={sampleActivity}
            onClose={closeForm}
        >
            <SampleRequestActivity
            closeForm={closeForm}
            // id={sampleId}
            // sampleName={sampleName}
            getActivityData={allActivityData}
            ></SampleRequestActivity>
        </Drawer>
        </>
    )
}

export default StatusRemark
