import React, {useEffect, useState} from 'react'
import ArrowLeft from "../../assets/keyboard_arrow_left.svg"
import UserIcon from "../../assets/user-gray.svg"
import "./Activity.css"
import axios from 'axios'
import config from '../utils/config'
import moment from 'moment'

const DARActivity = ({closeForm,id,sampleName,getDarNewStatus}) => {
    const [activity,setActivity] = useState([])
    useEffect(()=> {
        const fetchStatus = async () => {
            const res = await axios.post(config.ipAddress + `/getdaractivity`, {visitid:id})
            console.log('status',res?.data?.data?.activities)
            const getRes = res?.data?.data?.activities
            setActivity(getRes)
        }
        fetchStatus()
      },[]);
    return (
        <div className='drawer'>
            <div className="drawer-header">
                <div
                    className="flex flex-wrap items-center cursor-pointer drawer-heading"
                    onClick={() => closeForm()}
                >
                    <img alt="" src={ArrowLeft} className="mr-3" />
                    <span>Activity</span>
                </div>
            </div>
            <div className="drawer-body">
                <div className="subheading">{sampleName}</div>
                <div className="status-list">
                    <ul>
                        {activity?.length ?
                            activity?.map((item) => {
                                return <li>
                                    {item?.extra1 == item?.status ?
                                        ''
                                        :
                                        <p><label>Status</label> Changed from <strong>{item?.extra1}</strong> to <strong>{item?.status}</strong></p>
                                    }
                                    {item?.remark ? <p><label>Remark</label> {item?.remark}</p> : ''}
                                    {item?.extra2 ? <p><label>LR No.</label> {item?.extra2}</p> : ''}
                                    {item?.extra3 ? <p><label>Transporter Detail</label> {item?.extra3}</p> : ''}
                                    <div className='flex flex-wrap'>
                                        <div className='date'>{moment(item?.updated_at).format('MMM Do YYYY, h:mm:ss a')} </div>
                                        <span className='mx-2'>|</span>
                                        <div className="flex flex-wrap items-center user text-[12px]"><img src={UserIcon} alt="" className='w-5 mr-2' /> {item?.email ? item?.email : 'Admin'}</div>
                                    </div>
                                </li>
                            })
                            :
                            <p className='text-[14px] text-[#757a7f]'>No Activity Available</p>
                        }
                    </ul>
                </div>
            </div>
            <div className="drawer-footer"></div>
        </div>
    )
}

export default DARActivity
