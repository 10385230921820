import React, {useEffect, useState} from "react";
import { Route, Routes } from "react-router-dom";
// import Sidebar from "./component/sidebar/Sidebar";
import Dashboard from "./component/dashboard/Dashboard";
import Report from "./component/request/Report";
// import Login from "./component/login/Login";
import Upload from "./component/upload/Upload";
// import Navbar from "./component/navbar/Navbar";
import Loginpage from "./component/login/Loginpage";
// import Demo from "./component/request/Demo";
// import Drag from "./component/upload/Drag";
import "./App.css"
import { AuthProvider } from "./context/UserContext";
import RoutesIndex from "./Routes/Index";

function App() {

  const [roleData, setRoleData] = useState();
  useEffect( ()=> {
    let rols = localStorage.getItem('role')
    setRoleData(rols)
  },[roleData])

  console.log("roledata", roleData);
  
  return (
    <>
      <AuthProvider>
        {console.log('test')}
        <RoutesIndex roleData={roleData} setRoleData={setRoleData}></RoutesIndex>
      </AuthProvider>
    </>
  );
}

export default App;
